import queryString from "query-string";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Loading from "Components/Loading";
import { CoupangApi } from "api";
import Product from "Components/Product";
import CheckFooter from "./Footer";
import KeywordList from "./KeywordList";
import { KakaoLinkDefault } from "react-kakao-link";

const Container = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 0.5%;
`;

const Header = styled.div`
  display: flex;
  width: 90%;
  padding-top: 10px;
  justify-content: center;
  padding-left: 5%;
  align-items: center;
  @media only screen and (max-width: 750px) {
    padding-top: 0;
    display: inline-block;
    width: 95%;
    justify-content: flex-start;
    padding-left: 0;
    align-items: center;
  }
`;
const HeaderImg = styled.img`
  height: 52px;
  flex: 1;
  padding-top: 6%;
  @media only screen and (max-width: 750px) {
    padding-top: 1%;
    padding-bottom: 4%;
    height: 32px;
  }
`;

const A = styled.a`
  margin-left: 17px;
  text-decoration-line: none;
  text-decoration-color: #4d5159;
`;

const Link = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const SNS = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4d5159;
  overflow: hidden;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
  @media only screen and (max-width: 750px) {
    font-size: 14px;
  }
`;

const Form = styled.form`
  flex: 4;
  display: flex;
  align-items: center;
  margin: 0 1% 0 4%;
  height: 48px;
  @media only screen and (max-width: 750px) {
    flex: 4;
    height: 32px;
    padding-bottom: 20px;
  }
`;

const ProductContainer = styled.div`
  margin-top: 140px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-left: 0;
  @media only screen and (max-width: 750px) {
    margin-top: 150px;
    padding-left: 0px;
    flex-wrap: wrap;
    width: 100%;
    display: block;
  }
  @media only screen and (min-width: 780px) {
    width: 90%;
    margin-left: 10%;
  }
`;

const JoongoContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1%;
  flex-wrap: wrap;
  margin-left: 0;
  @media only screen and (max-width: 750px) {
    padding-left: 0px;
    flex-wrap: wrap;
    width: 100%;
    display: block;
  }
  @media only screen and (min-width: 780px) {
    width: 90%;
    margin-left: 10%;
  }
`;

const TXT = styled.div`
  box-sizing: border-box;
  font-size: 19px;
  width: 100%;
  border-bottom: 0.1rem solid;
  border-color: #d7d2ce;
  padding: 0.5% 0 0.5% 2%;
  @media only screen and (max-width: 750px) {
    padding: 1.5% 0 1.5% 1%;
    font-size: 11px;
  }
`;

const SUBTXT = styled.div`
  box-sizing: border-box;
  font-size: 19px;
  color: #00792f;
  width: 90%;
  border-bottom: 0.2rem solid;
  font-weight: bold;
  border-color: #00792f;
  padding: 0.5% 0 0.5% 1%;
  @media only screen and (max-width: 750px) {
    padding: 3% 0 0.5% 1%;
    width: 100%;
    font-size: 15px;
  }
`;

const Fixed = styled.div`
  position: fixed;
  width: 100%;
  background-color: white;
  padding-top: 8px;
  top: 0px;
  top: 0;
  left: 0;
  z-index: 1;
`;
const SUBTXTContainer = styled.div`
  width: 100%;
  display: flex;
`;

const FindProduct = (keyword, regionCheck) => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState({ latitude: "", longitude: "" });

  async function findProduct() {
    if (regionCheck.checked == false) {
      // navigator.geolocation.getCurrentPosition(function (position) {
      //   setRegion({
      //     latitude: position.coords.latitude,
      //     longitude: position.coords.longitude,
      //   });
      // });
    } else {
      setRegion({
        latitude: "",
        longitude: "",
      });
    }
    try {
      setLoading(true);
      keyword = keyword.replace("중고", "");
      const {
        data: { body: data },
      } = await CoupangApi.getProduct(keyword);
      // const {
      //   data: { body: data2 },
      // } = await CoupangApi.getJoongo(
      //   keyword,
      //   region["latitude"],
      //   region["longitude"]
      // );

      if (typeof data === "undefined") {
        setData([]);
      } else {
        setData(data);
      }
      // if (typeof data2 === "undefined") {
      //   setData2([]);
      // } else {
      //   setData2(data2);
      // }
    } catch (err) {
      // console.log(err);
    } finally {
      setLoading(false);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 500);
    }
  }

  useEffect(() => {
    findProduct();
  }, [keyword, regionCheck]);
  return { data, data2, loading };
  // return { data, loading };
};

const Main = ({ match, location }) => {
  const query = queryString.parse(location.search);
  const [keyword, setKeyword] = useState(query["keyword"]);
  const [regionCheck, setregionCheck] = useState({
    checked: false,
  });

  const handleRegionChange = (e) => {
    setregionCheck({
      ...regionCheck,
      [e.target.name]: e.target.checked,
    });
  };

  // console.log("플랫폼 문의: master@gold-keyword.info");
  console.log(keyword);
  const { data, data2, loading } = FindProduct(keyword, regionCheck);

  let imageUrl = "";
  let comment = "";
  if (data.length > 0) {
    if (data.length < 2) {
      imageUrl = data[0]["imgSrc"];
      comment = data[0]["ratingCount"].replace(",", "");
    } else {
      imageUrl = data[1]["imgSrc"];
      comment = data[1]["ratingCount"].replace(",", "");
    }
  }

  const template = {
    objectType: "feed",
    content: {
      title: `${keyword} 할인 정보 - .Shop`,
      description: `${keyword} 할인정보, 가격, 평점, 리뷰 알아보기`,
      imageUrl: imageUrl,
      link: {
        mobileWebUrl: window.location.href,
        webUrl: window.location.href,
      },
    },
    social: {
      likeCount: Number(comment) * 9,
      commentCount: Number(comment),
      sharedCount: Number(Math.ceil(comment * 0.2)),
    },
    buttons: [
      {
        title: "할인 정보 확인하기",
        link: {
          mobileWebUrl: window.location.href,
          webUrl: window.location.href,
        },
      },
      // {
      //   title: "앱으로 보기",
      //   link: {
      //     mobileWebUrl: window.location.href,
      //     webUrl: window.location.href,
      //   },
      // },
    ],
  };

  const template2 = {
    objectType: "feed",
    content: {
      title: `가성비 좋은 상품을 찾고 계신가요? - .shop!`,
      description: `평점 높은 가성비 좋은 상품들만 알아볼까요?`,
      imageUrl: window.location.href + "/logo.png",
      link: {
        mobileWebUrl: window.location.href,
        webUrl: window.location.href,
      },
    },
    social: {
      likeCount: 7491,
      commentCount: 713,
      sharedCount: 9158,
    },
    buttons: [
      {
        title: "할인 정보 확인하기",
        link: {
          mobileWebUrl: window.location.href,
          webUrl: window.location.href,
        },
      },
      // {
      //   title: "앱으로 보기",
      //   link: {
      //     mobileWebUrl: window.location.href,
      //     webUrl: window.location.href,
      //   },
      // },
    ],
  };

  if (loading) {
    return (
      <Container>
        <Fixed>
          <Header>
            <A href="https://dotshop.link">
              <HeaderImg src="/logo.png" />
            </A>
            <Form>
              <KeywordList initKeyword={keyword} />
              <Link>
                <KakaoLinkDefault
                  className="template"
                  template={template2}
                  jsKey={"6bfde7d4ca61707f8a6154998304759d"}
                >
                  <SNS>
                    <div
                      style={{
                        borderRadius: "20%",
                        overflow: "hidden",
                        marginRight: "9px",
                        height: "50px",
                        width: "50px",
                      }}
                    >
                      <img
                        src="./kakao.png"
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                    공유하기
                  </SNS>
                </KakaoLinkDefault>
              </Link>
            </Form>
          </Header>
          <TXT>
            쇼핑몰에서 평점 높은 가성비 좋은 상품들만 선별하여 제공합니다.
          </TXT>
        </Fixed>
        <Loading />
      </Container>
    );
  } else if (loading == false && data.length > 0 && keyword) {
    return (
      <Container>
        <Fixed>
          <Header>
            <A href="https://dotshop.link">
              <HeaderImg src="/logo.png" />
            </A>
            <Form>
              <KeywordList initKeyword={keyword} />
              <Link>
                <KakaoLinkDefault
                  className="template"
                  template={template}
                  jsKey={"6bfde7d4ca61707f8a6154998304759d"}
                >
                  <SNS>
                    <div
                      style={{
                        borderRadius: "20%",
                        overflow: "hidden",
                        marginRight: "9px",
                        height: "50px",
                        width: "50px",
                      }}
                    >
                      <img
                        src="./kakao.png"
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                    공유하기
                  </SNS>
                </KakaoLinkDefault>
              </Link>
            </Form>
          </Header>
          <TXT>
            쇼핑몰에서 평점 높은 가성비 좋은 상품들만 선별하여 제공합니다.
          </TXT>
        </Fixed>
        <ProductContainer>
          <SUBTXT>쇼핑몰에서 평점 좋은 상품</SUBTXT>
          {data.map((key, index) => (
            <Product
              key={index}
              linkUrl={key.linkUrl}
              header={key.header}
              imgSrc={key.imgSrc}
              title={key.title}
              price={key.price}
              promise={key.promise}
              rating={key.rating}
              ratingCount={key.ratingCount}
            />
          ))}
        </ProductContainer>
        {/* <JoongoContainer>
          <SUBTXTContainer>
            <SUBTXT style={{ marginTop: "1.3%" }}>
              [미개봉 중고상품] - 모든 지역
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={regionCheck.checked}
                    onChange={handleRegionChange}
                    name="checked"
                    styled={{ marginRight: "0px" }}
                  />
                }
              />
              내 근처 지역
            </SUBTXT>
          </SUBTXTContainer>
          {data2.map((key, index) => (
            <Joongo
              key={index}
              header={""}
              imgSrc={key.thumnail}
              link={key.link}
              title={key.title}
              price={key.price}
              deliveryType={key.deliveryType}
              region={key.region}
              writeTime={key.writeTime}
            />
          ))}
        </JoongoContainer> */}
        <CheckFooter data={data} keyword={keyword} />
      </Container>
    );
  } else if (loading == false && data.length == 0) {
    return (
      <Container>
        <Fixed>
          <Header>
            <A href="https://dotshop.link">
              <HeaderImg src="/logo.png" />
            </A>
            <Form>
              <KeywordList initKeyword={keyword} />
              <Link>
                <KakaoLinkDefault
                  className="template"
                  template={template2}
                  jsKey={"6bfde7d4ca61707f8a6154998304759d"}
                >
                  <SNS>
                    <div
                      style={{
                        borderRadius: "20%",
                        overflow: "hidden",
                        marginRight: "9px",
                        height: "50px",
                        width: "50px",
                      }}
                    >
                      <img
                        src="./kakao.png"
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                    공유하기
                  </SNS>
                </KakaoLinkDefault>
              </Link>
            </Form>
          </Header>
          <TXT>
            쇼핑몰에서 평점 높은 가성비 좋은 상품들만 선별하여 제공합니다.
          </TXT>
        </Fixed>
        <CheckFooter data={data} keyword={keyword} />
      </Container>
    );
  }
};
export default Main;
