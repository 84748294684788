import axios from "axios";

const api = axios.create({
  baseURL:
    "https://786t41c7wh.execute-api.ap-northeast-2.amazonaws.com/dotshop/",
  credentials: "include",
  params: {
    lptag: "AF9130333",
    subid: "dotshoplink",
  },
});

export const CoupangApi = {
  getProduct: (keyword) =>
    api.get("coupang", {
      params: {
        keyword: keyword,
      },
    }),
  getJoongo: (keyword, latitude, longitude) =>
    api.get("joongo", {
      params: {
        keyword: keyword,
        latitude: latitude,
        longitude: longitude,
      },
    }),
  searchKeyword: (keyword) =>
    api.get("search", {
      params: {
        keyword: keyword,
      },
    }),
};
